import {
  ErrorBoundary,
  ErrorPage,
  FullPageLoader,
  useOnMount,
} from '@kivra/react-components';
import { updateRequestConfig } from '@kivra/sdk/common';
import { captureException } from '@kivra/sdk/log';
import { getDefinedValue } from '@sender-portal-shared/sdk/src/assert-defined';
import React, { useReducer } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { initState, reducer } from './appState';
import { bootstrap } from './bootstrap';
import { getLocalCopy, getRemoteCopy } from './bootstrap/copy';
import { GlobalContextProvider } from './globalContext';

function AppRoot(): React.ReactNode {
  const [{ appOptions, config, copy, bootError }, dispatch] = useReducer(
    reducer,
    initState
  );

  useOnMount(async () => {
    try {
      getLocalCopy()
        .then(copy => dispatch({ type: 'update_copy', copy }))
        .catch((error: unknown) =>
          dispatch({ type: 'set_boot_error', error: error as Error })
        );
      const { appOptions, config } = await bootstrap();
      dispatch({ type: 'update_config', config });
      updateRequestConfig({ senderOrigin: config.sender_api_origin });
      dispatch({ type: 'update_app_options', appOptions });
      void getRemoteCopy().then(copy =>
        dispatch({ type: 'update_copy', copy })
      );
    } catch (error) {
      dispatch({ type: 'set_boot_error', error });
    }
  });

  if (bootError) {
    // Will throw and let ErrorBoundary take care of it
    throw bootError;
  }

  if (!appOptions || !config || !copy) {
    return <FullPageLoader />;
  }

  return (
    <GlobalContextProvider
      value={{
        config,
        copy,
        appOptions,
        global: window,
      }}
    >
      <App />
    </GlobalContextProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(getDefinedValue(container));

function run(): void {
  root.render(
    <ErrorBoundary
      ErrorComponent={() => <ErrorPage useDefaultTexts />}
      onCatch={error => {
        captureException(error);
      }}
    >
      <AppRoot />
    </ErrorBoundary>
  );
}

run();
