import { Body, Button, Margin, TextField } from '@kivra/react-components';
import { ApiResponseError, navigate } from '@kivra/sdk/common';
import {
  authenticateOTP,
  sendOTP,
} from '@sender-portal-fe/util-shared/src/sdk/authentication/otp';
import React, { useState } from 'react';
import { useAppOptions, useCopy } from '../globalContext';
import { isValidEmail, isValidOtp } from '../util/validate';

enum errorState {
  incorrectOtp = 'incorrectOtp',
  malformedEmail = 'malformedEmail',
  malformedOtp = 'malformedOtp',
  sendEmailError = 'sendEmailError',
  unknownError = 'unknownError',
}

export const VerificationCode: React.FC = () => {
  const { email: emailHint, state, redirectUri, clientId } = useAppOptions();
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);
  const [email, setEmail] = useState(emailHint || '');
  const [otp, setOTP] = useState('');
  const [error, setError] = useState<errorState | null>(null);

  const copy = useCopy();

  const handleSendOTP = async (): Promise<void> => {
    if (isValidEmail(email)) {
      try {
        await sendOTP({ email, state, clientId, redirectUri });
        setCodeHasBeenSent(true);
      } catch {
        setError(errorState.sendEmailError);
      }
    } else {
      setError(errorState.malformedEmail);
    }
  };

  const handleAuthenticateOTP = (): void => {
    if (isValidOtp(otp)) {
      authenticateOTP({ email, state, otp, clientId, redirectUri })
        .then(({ redirect_uri }) => {
          navigate(redirect_uri);
        })
        .catch((error: unknown) => {
          if (
            error instanceof ApiResponseError &&
            error.httpStatusCode === 401
          ) {
            setError(errorState.incorrectOtp);
          } else {
            setError(errorState.unknownError);
          }
        });
    } else {
      setError(errorState.malformedOtp);
    }
  };

  const getErrorMessage = (error: errorState | null): string | undefined => {
    switch (error) {
      case errorState.incorrectOtp:
        return copy('heimdall__incorrect_otp_error');
      case errorState.malformedEmail:
        return copy('heimdall__malformed_email_error');
      case errorState.malformedOtp:
        return copy('heimdall__malformed_otp_error');
      case errorState.sendEmailError:
        return copy('heimdall__send_email_error');
      case errorState.unknownError:
        return copy('error_generic__unexpected_error__try_again');
      default:
        return undefined;
    }
  };

  return (
    <>
      <Margin bottom={16}>
        <Body size="medium" align="center" color="$text-secondary">
          {codeHasBeenSent
            ? copy('heimdall__otp_has_been_sent')
            : copy('heimdall__otp_will_be_sent')}
          {codeHasBeenSent && <Body size="medium"> {email}</Body>}
        </Body>
      </Margin>
      <form
        onSubmit={e => {
          e.preventDefault();
          codeHasBeenSent ? handleAuthenticateOTP() : void handleSendOTP();
        }}
      >
        {codeHasBeenSent ? (
          <>
            <TextField
              autoFocus
              size="large"
              placeholder="XXXXXX"
              descriptionMessage={copy('heimdall__otp_input__tooltip')}
              errorMessage={getErrorMessage(error)}
              value={otp}
              onTextChange={text => {
                setError(null);
                setOTP(text);
              }}
            />
          </>
        ) : (
          <TextField
            autoFocus
            value={email}
            placeholder={copy('heimdall__email_label')}
            onTextChange={text => {
              setError(null);
              setEmail(text);
            }}
            errorMessage={getErrorMessage(error)}
          />
        )}
        <Margin top={24}>
          {codeHasBeenSent ? (
            <>
              <Button size="medium" type="submit">
                {copy('heimdall__sign_in')}
              </Button>
              <Margin top={24}>
                <Button
                  variant="link"
                  size="medium"
                  onClick={() => {
                    setError(null);
                    setOTP('');
                    setCodeHasBeenSent(false);
                  }}
                >
                  {copy('heimdall__resend_otp')}
                </Button>
              </Margin>
            </>
          ) : (
            <Button size="medium" type="submit">
              {copy('heimdall__send_otp')}
            </Button>
          )}
        </Margin>
      </form>
    </>
  );
};
