import { createContext, useContext } from 'react';
import { getCopyString } from '@kivra/sdk/copy-consumer';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import type { Copy, Config, AppOptions } from './types';
import type { GetCopyArguments } from './copy';

export interface GlobalContext {
  copy: Copy;
  config: Config;
  appOptions: AppOptions;
  global: Window;
}
const GlobalContext = createContext<GlobalContext>(null as FixThisAnyLater);

const { Consumer: GlobalContextConsumer, Provider: GlobalContextProvider } =
  GlobalContext;

export { GlobalContextProvider, GlobalContextConsumer };

const hookCreator =
  <T>(map: (gc: GlobalContext) => T) =>
  (): T => {
    const global = useContext(GlobalContext);
    return map(global);
  };

export const useConfig = hookCreator(g => g.config);
export const useCopy = hookCreator(g =>
  getCopyString<GetCopyArguments>(g.copy)
);
export const useAppOptions = hookCreator(g => g.appOptions);
export const useGlobal = hookCreator(g => g.global);
export const useGlobalContext = hookCreator(g => g);
