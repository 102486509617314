import { normalizeUrl } from '@sender-portal-fe/util-shared/src/bootstrap/normalizeUrl';
import type { AppOptions, Config } from '../types';
import { getAppOptions } from './appOptions';
import { getConfig } from './config';
import { initSentry } from './sentry';

export async function bootstrap(): Promise<{
  config: Config;
  appOptions: AppOptions;
}> {
  const config = await getConfig();
  initSentry(config);
  const appOptions = getAppOptions(window.location.href);
  normalizeUrl(window.location);

  return { config, appOptions };
}
