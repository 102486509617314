import { getAndDecodeOption } from '@kivra/sdk/common';
import type { AppOptions } from '../types';

export function getAppOptions(href: string): AppOptions {
  return {
    locale: getAndDecodeOption('locale', href),
    prefersColorScheme: getPrefersColorScheme(
      getAndDecodeOption('prefers_color_scheme', href)
    ),
    authenticationMethods:
      getAndDecodeOption('auth_methods', href)?.split(',') || [],
    clientName: getAndDecodeOption('client_name', href),
    clientId: getAndDecodeOption('client_id', href) || '',
    redirectUri: getAndDecodeOption('redirect_uri', href) || '',
    email: getAndDecodeFragment('email', href),
    state: getAndDecodeOption('state', href) || '',
  };
}

function getPrefersColorScheme(scheme?: string): 'light' | 'dark' | undefined {
  switch (scheme) {
    case 'light':
    case 'dark':
      return scheme;
    default:
      return undefined;
  }
}

function getAndDecodeFragment(key: string, href: string): string | undefined {
  const value = href.split('#')[1]?.split(`${key}=`)[1]?.split('&')[0];
  return (value && decodeURIComponent(value)) || undefined;
}
