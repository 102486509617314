import { fetchCopy } from '@kivra/sdk/copy-consumer';
import type { Copy } from '../types';

const languages = {
  localPath: {
    sv: '/sv.json',
    en: '/en.json',
  },
  remotePath: {
    sv: 'https://static.kivra.com/copy/sv-se/tsm.json?cache=0',
    en: 'https://static.kivra.com/copy/en-US/tsm.json?cache=0',
  },
};

export function getRemoteCopy(): Promise<Copy> {
  return fetchCopy<Copy>(languages.remotePath, 'se').then(({ copy }) => copy);
}

export function getLocalCopy(): Promise<Copy> {
  return fetchCopy<Copy>(languages.localPath, 'se').then(({ copy }) => copy);
}
