import { initLogger } from '@kivra/sdk/log';
import type { Config } from '../types';

export function initSentry(config: Config): void {
  const enabled = window.location.port === '';

  initLogger({
    dsn: config.sentry_dsn_heimdall_ui,
    enabled,
    environment: process.env.NODE_ENV,
  });
}
