import React from 'react';
import { SignInButton } from '../components/SignInButton';
import type { IdentityProvider } from '../types';

type Props = {
  identityProviders: IdentityProvider[];
};

export const IdentityProviders: React.FC<Props> = ({ identityProviders }) => {
  return (
    <>
      {identityProviders.map(idp => (
        <SignInButton {...idp} key={idp.id} />
      ))}
    </>
  );
};
