import { makeRequest } from '@kivra/sdk/common';
import type { Config } from '../types';

export function getConfig(): Promise<Config> {
  console.assert(window.CONFIG_URL, 'window.CONFIG_URL is missing.');
  return makeRequest({
    method: 'get',
    url: window.CONFIG_URL,
  });
}
