// This file is copied from kivra_fe_sdk repo
import {
  ApiResponseError,
  convertPropertyNamesToSnakeCase,
  navigate,
  senderRequest,
} from '@kivra/sdk/common';
import { captureException } from '@kivra/sdk/log';

type otpPayload = {
  email: string;
  state: string;
  otp: string;
  clientId: string;
  redirectUri: string;
};

type ErrorRedirect = ApiResponseError & {
  data: {
    redirect_uri: string;
  };
};

export const sendOTP = async (
  payload: Omit<otpPayload, 'otp'>
): Promise<unknown> => {
  try {
    return await senderRequest.post({
      path: '/v2/otp_auth/challenge',
      payload: convertPropertyNamesToSnakeCase(payload),
    });
  } catch (e) {
    handleRedirectError(e);
    throw e;
  }
};

export const authenticateOTP = async (
  payload: otpPayload
): Promise<{ redirect_uri: string }> => {
  try {
    return await senderRequest.post<{ redirect_uri: string }>({
      path: '/v2/otp_auth/challenge_callback',
      payload: convertPropertyNamesToSnakeCase(payload),
    });
  } catch (e) {
    handleRedirectError(e);
    throw e;
  }
};

const handleRedirectError = (error: unknown): void => {
  captureException(error);
  if (isErrorRedirect(error)) {
    navigate(error.data.redirect_uri);
  }
};

const isErrorRedirect = (error: unknown): error is ErrorRedirect =>
  error instanceof ApiResponseError
    ? (error.data as Record<string, unknown> | undefined)?.code === '40041'
    : false;
