import { Body, Button, Heading, Margin, styled } from '@kivra/react-components';
import { LockIcon } from '@kivra/react-components/icons';
import React, { useState } from 'react';
import { BaseLayout } from '../components/BaseLayout';
import { Card } from '../components/Card';
import { CardSection } from '../components/CardSection';
import { Headline } from '../components/Headline';
import { KIVRA_OTP, identityProviderList } from '../constants';
import { useAppOptions, useConfig, useCopy } from '../globalContext';
import type { IdentityProvider } from '../types';
import { IdentityProviders } from './IdentityProviders';
import { VerificationCode } from './VerificationCode';

const TextLink = styled('a')({
  textDecoration: 'underline',
  color: '$text-primary',
});

const Center = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 0,
  padding: ['$spacing-48', '$spacing-64', '$spacing-32'],
  $small: {
    padding: ['$spacing-48', '$spacing-8', '$spacing-32'],
  },
});

const getIdentityProvider = (id: string): IdentityProvider =>
  identityProviderList.find(idp => idp.id === id.toLocaleLowerCase()) || {
    id,
    displayName: id,
  };

const SignInPage: React.FC = () => {
  const copy = useCopy();
  const { authenticationMethods } = useAppOptions();
  const identityProviders: IdentityProvider[] = authenticationMethods
    .filter(id => id !== KIVRA_OTP)
    .map(getIdentityProvider);
  const hasKivraOTP = Boolean(
    authenticationMethods.find(id => id === KIVRA_OTP)
  );

  const hasIdentityProviders = identityProviders.length > 0;
  const [showVerificationCodeTab, setShowVerificationCodeTab] = useState(
    !hasIdentityProviders
  );
  const areBothTabsEnabled = hasIdentityProviders && hasKivraOTP;

  const config = useConfig();

  const signInHeader = showVerificationCodeTab
    ? copy('heimdall__otp_auth_option')
    : copy('heimdall__choose_login_method');

  return (
    <BaseLayout>
      <Card data-test-id="root-card">
        <CardSection>
          <Headline />
        </CardSection>
        <CardSection style={{ flex: 1 }}>
          <Center>
            <Heading size="large">{signInHeader}</Heading>
            <div style={{ minHeight: 136, width: '100%', textAlign: 'center' }}>
              {showVerificationCodeTab ? (
                <VerificationCode />
              ) : (
                <IdentityProviders identityProviders={identityProviders} />
              )}
              {areBothTabsEnabled && !showVerificationCodeTab && (
                <Margin bottom={80}>
                  <Button
                    size="medium"
                    variant="secondary"
                    onClick={() => setShowVerificationCodeTab(true)}
                    fullWidth
                  >
                    <Button.Icon iconComponent={LockIcon} />
                    {copy('heimdall__otp_button')}
                  </Button>
                </Margin>
              )}
            </div>

            {areBothTabsEnabled && showVerificationCodeTab && (
              <Margin top={80} bottom={8}>
                <Button
                  variant="link"
                  size="medium"
                  onClick={() => setShowVerificationCodeTab(false)}
                >
                  {copy('heimdall__other_auth_options')}
                </Button>
              </Margin>
            )}
            {!areBothTabsEnabled && <Margin top={80} bottom={8} />}
          </Center>
        </CardSection>
        <CardSection>
          <Margin top={16} bottom={16}>
            <Body size="small" color="$text-secondary">
              {`${copy('heimdall__gdpr_disclaimer')} `}
              <TextLink href={config.kivra_personal_data_terms_url__general}>
                {`${copy('link__here')}.`}
              </TextLink>
            </Body>
          </Margin>
        </CardSection>
      </Card>
    </BaseLayout>
  );
};

export { SignInPage };
