import { styled } from '@kivra/react-components';

export const Card = styled('div')({
  background: '$background-secondary',
  boxShadow: '$shadow-raised',
  borderRadius: '$radius-small',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '530px',
  margin: 'auto',
});
