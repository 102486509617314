import type { AppOptions, Config, Copy, IdentityProvider } from './types';

export interface AppState {
  copy: Copy | null;
  config: Config | null;
  appOptions: AppOptions | null;
  bootError: Error | null;
  authenticationMethods: IdentityProvider[] | null;
}

export const initState = {
  copy: null,
  config: null,
  appOptions: null,
  bootError: null,
  authenticationMethods: null,
};

export type Actions =
  | { type: 'update_copy'; copy: Partial<Copy> }
  | { type: 'update_config'; config: Partial<Config> }
  | { type: 'update_app_options'; appOptions: Partial<AppOptions> }
  | {
      type: 'set_authentication_methods';
      authenticationMethods: IdentityProvider[];
    }
  | { type: 'set_boot_error'; error: unknown };

export function reducer(state: AppState, actions: Actions): AppState {
  const updateState = <T extends keyof AppState>(
    oldState: AppState,
    part: T,
    newPart: Partial<AppState[T]>
  ): AppState => {
    return { ...oldState, [part]: { ...oldState[part], ...newPart } };
  };

  switch (actions.type) {
    case 'update_copy':
      return updateState(state, 'copy', actions.copy);
    case 'update_config':
      return updateState(state, 'config', actions.config);
    case 'update_app_options':
      return updateState(state, 'appOptions', actions.appOptions);
    case 'set_authentication_methods':
      return updateState(
        state,
        'authenticationMethods',
        actions.authenticationMethods
      );
    case 'set_boot_error':
      return {
        ...state,
        bootError:
          actions.error instanceof Error
            ? actions.error
            : new Error('unknown error'),
      };
  }
  return state;
}
