import {
  GreenLogo,
  Margin,
  useIsMediumScreenSize,
} from '@kivra/react-components';
import React from 'react';
import { useAppOptions } from '../globalContext';

const Headline: React.FC = () => {
  const isLargeScreenSize = !useIsMediumScreenSize();
  const { clientName = '' } = useAppOptions();
  document.title = `Kivra ${clientName}`;
  return (
    <Margin top={20} bottom={20}>
      <GreenLogo
        text={clientName}
        textPosition="bottom"
        size={isLargeScreenSize ? 'large' : 'medium'}
        href="#"
      />
    </Margin>
  );
};

export { Headline };
