import type { IdentityProvider } from './types';

export const KIVRA_OTP = 'kivra_otp';

export const identityProviderList: IdentityProvider[] = [
  {
    id: 'microsoft',
    displayName: 'Microsoft',
    logoPath: '/assets/logo/microsoft_logo.svg',
  },
  {
    id: 'google',
    displayName: 'Google',
    logoPath: '/assets/logo/google_g_logo.svg',
  },
];

export const OTP_LENGTH = 6;
