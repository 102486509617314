import React from 'react';
import { KivraTheme } from '@kivra/react-components';
import { useAppOptions } from './globalContext';
import { Debug } from './Debug';
import { SignInPage } from './pages/SignInPage';

export const App = React.memo(() => {
  const { prefersColorScheme, authenticationMethods, state } = useAppOptions();

  if (authenticationMethods.length === 0) {
    throw new Error('No authentication methods have been specified');
  }

  if (!state) {
    throw new Error('No state has been specified');
  }

  return (
    <KivraTheme colorScheme={prefersColorScheme}>
      <Debug />
      <SignInPage />
    </KivraTheme>
  );
});
