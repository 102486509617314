import React from 'react';
import { Button, css } from '@kivra/react-components';
import { navigate } from '@kivra/sdk/common';
import type { IdentityProvider } from '../types';
import { useConfig, useAppOptions } from '../globalContext';

type SignInButtonProps = IdentityProvider;

const SignInButton: React.FC<SignInButtonProps> = ({
  displayName,
  logoPath,
  id,
}: SignInButtonProps) => {
  const { state, clientId, redirectUri } = useAppOptions();
  const config = useConfig();
  const authenticationURL = `${config.sender_api_origin}/v2/oauth2/continue?state=${state}&auth_method=${id}&client_id=${clientId}&redirect_uri=${redirectUri}`;
  return (
    <div
      className={css({
        '& button': {
          marginTop: '$spacing-8',
          marginBottom: '$spacing-8',
          padding: 0,
        },
      })}
    >
      <Button
        variant="secondary"
        size="medium"
        onClick={() => navigate(authenticationURL)}
        fullWidth={true}
      >
        {logoPath && (
          <img
            src={`${config.kivra_static_origin}${logoPath}`}
            width={31}
            height={31}
          />
        )}
        {displayName}
      </Button>
    </div>
  );
};

export { SignInButton };
