import { type GlobalContext, useGlobalContext } from './globalContext';

export const Debug: React.FC = () => {
  const context = useGlobalContext();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.kivra = window.kivra ?? {};

  window.kivra.inspectGlobalContext = (key?: keyof GlobalContext) => {
    let obj;
    if (key && key in context) {
      obj = context[key];
    } else if (key) {
      console.warn(`"${key}" does not exist in the context`);
    }
    console.group(
      '%c Global Context ',
      'background: rgb(124, 179, 59); color: #fff'
    );
    console.dir(obj);
    console.groupEnd();
    return obj;
  };
  return null;
};
